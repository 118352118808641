import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import { gql } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import classnames from 'classnames';
import { format } from 'date-fns';
import {
    GraphQLGiftCardWithDetails,
    GiftCardTransactionHistoryResponse,
    GiftCardTransactionHistoryArguments,
    GiftCardTransaction,
} from '@bondvet/types/giftCards';
import useGiftCardsQuery from 'hooks/useGiftCardsQuery';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import useTranslate from 'hooks/useTranslate';
import useLocationId from 'hooks/useLocationId';
import { closeFlyout, openVetspirePage } from 'lib/vetspireActions';
import styles from './TransactionsHistoryDialog.module.scss';

export const giftCardTransactionHistoryQuery = gql`
    query giftCardTransactionHistory(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardId: String!
    ) {
        giftCardTransactionHistory(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardId: $giftCardId
        ) {
            id
            transactionTime
            amount
            orderId
            clientId
            patientId
            orderDateTime
        }
    }
`;

type TransactionsHistoryDialogProps = {
    onClose: () => void;
    clientId: string;
    giftCard: GraphQLGiftCardWithDetails;
};

export default function TransactionsHistoryDialog({
    onClose,
    clientId,
    giftCard,
}: TransactionsHistoryDialogProps) {
    const vetspireLocationId = useLocationId();
    const translate = useTranslate();
    const { _id: id, last3, balance } = giftCard;
    const moneyFormatter = useMoneyFormatter({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
    });

    const { data } = useGiftCardsQuery<
        GiftCardTransactionHistoryResponse,
        GiftCardTransactionHistoryArguments
    >(giftCardTransactionHistoryQuery, {
        variables: {
            vetspireLocationId,
            giftCardId: id,
            vetspireClientId: clientId,
        },
    });

    const { transactions, originalBalance } = React.useMemo(() => {
        const sorted = orderBy(
            data?.giftCardTransactionHistory ?? [],
            ['transactionTime'],
            ['asc'],
        );

        if (sorted.length === 0) {
            return { transactions: [], originalBalance: 0 };
        }
        return {
            transactions: sorted.slice(1),
            originalBalance: sorted[0].amount,
        };
    }, [data?.giftCardTransactionHistory]);

    const handleOpenInovicePage = React.useCallback(
        (invoiceClientId: string, patientId: string, orderId: string) => {
            openVetspirePage(
                `/clients/${invoiceClientId}/patients/${patientId}/billing/invoices/${orderId}`,
            );
            if (invoiceClientId !== clientId) {
                closeFlyout();
            }
        },
        [clientId],
    );

    const mapTransaction = React.useCallback(
        ({
            id: tid,
            amount,
            orderId,
            orderDateTime,
            clientId: invoiceClientId,
            patientId,
        }: GiftCardTransaction) => {
            let label: React.ReactElement;
            if (!orderId) {
                label = (
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.noInvoice',
                        )}
                    </div>
                );
            } else if (invoiceClientId && patientId) {
                label = (
                    <Link
                        className={styles.invoiceLink}
                        onClick={() =>
                            handleOpenInovicePage(
                                invoiceClientId,
                                patientId,
                                orderId,
                            )
                        }
                    >
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.label',
                            {
                                orderId,
                                date: orderDateTime
                                    ? format(orderDateTime, 'MMM dd, yyyy')
                                    : 'Invalid Date',
                            },
                        )}
                    </Link>
                );
            } else {
                label = (
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.label',
                            {
                                orderId,
                                date: orderDateTime
                                    ? format(orderDateTime, 'MMM dd, yyyy')
                                    : 'Invalid Date',
                            },
                        )}
                    </div>
                );
            }

            return (
                <div key={tid} className={styles.transaction}>
                    {label}
                    <div
                        className={classnames({
                            [styles.negative]: amount < 0,
                        })}
                    >
                        {moneyFormatter.format(amount)}
                    </div>
                </div>
            );
        },
        [handleOpenInovicePage, moneyFormatter, translate],
    );

    return (
        <Dialog open onClose={onClose} classes={{ paper: styles.root }}>
            <DialogTitle className={styles.title}>
                {translate(
                    'vetspireExtension.clientDetails.payments.giftCards.historyDialog.title',
                    {
                        last3,
                    },
                )}
            </DialogTitle>
            <DialogContent>
                <div className={styles.content}>
                    <div className={styles.transaction}>
                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.historyDialog.originalBalance',
                            )}
                        </div>
                        <div>{moneyFormatter.format(originalBalance)}</div>
                    </div>
                    {transactions.map(mapTransaction)}
                    <div className={styles.current}>
                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.historyDialog.currentBalance',
                            )}
                        </div>
                        <div>{moneyFormatter.format(balance)}</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={onClose}
                    type="button"
                    className={styles.closeButton}
                >
                    <CloseIcon />
                </button>
            </DialogActions>
        </Dialog>
    );
}
