import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { WithFlyout } from 'lib/types';
import { CreditCard } from 'api/types';
import { Order } from 'api/clients/queries';
import styles from './DueInvoices.module.scss';
import DueInvoice from '../DueInvoice';
import ChargeInvoice from '../ChargeInvoice';

type DueInvoicesProps = {
    clientId: string;
    classes?: Partial<WithFlyout<typeof styles>>;
    defaultCard: CreditCard | null;
    orders: ReadonlyArray<Order>;
    refetchOrders: () => Promise<void>;
};

function DueInvoices({
    clientId,
    classes,
    defaultCard,
    orders,
    refetchOrders,
}: DueInvoicesProps): React.ReactElement {
    const translate = useTranslate();
    const [selectedInvoice, setSelectedInvoice] = React.useState<Order | null>(
        null,
    );

    const handleSelectInvoice = (invoice: Order) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setSelectedInvoice(invoice);
            });
        });
    };

    const clearSelectedInvoice = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setSelectedInvoice(null);
            });
        });
    };

    return (
        <>
            <div
                className={classnames(
                    classes?.flyoutBlock,
                    styles.flyoutBlock,
                    styles.root,
                )}
            >
                <header>
                    <span className={styles.flyoutTitle}>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.title',
                        )}
                    </span>
                </header>
                <div className={styles.row}>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.date',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.provider',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.clinic',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.due',
                        )}
                    </h4>
                </div>
                {orders.map((invoice) => (
                    <DueInvoice
                        invoice={invoice}
                        key={invoice.id}
                        className={styles.row}
                        chargeEnabled={Boolean(defaultCard)}
                        onCharge={handleSelectInvoice}
                    />
                ))}
            </div>

            {selectedInvoice && defaultCard && (
                <ChargeInvoice
                    clientId={clientId}
                    defaultCard={defaultCard}
                    invoice={selectedInvoice}
                    refetchInvoice={refetchOrders}
                    onClose={clearSelectedInvoice}
                />
            )}
        </>
    );
}

export default DueInvoices;
