import * as React from 'react';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as GiftCardIcon } from 'assets/giftcard.svg';
import Chip from '@mui/material/Chip';
import type { GraphQLGiftCardWithDetails } from '@bondvet/types/giftCards';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useTranslate from 'hooks/useTranslate';
import useGiftCardsMutation from 'hooks/useGiftCardsMutation';
import { deleteGiftCard as deleteGiftCardMutation } from 'api/clients/mutations';
import { CircularProgress } from '@mui/material';
import {
    DeleteGiftCardResult,
    DeleteGiftCardArguments,
} from '@bondvet/types/giftCards';
import styles from './GiftCard.module.scss';
import TransactionsHistoryDialog from '../TransactionsHistoryDialog';

export type CardProps = {
    clientId: string;
    classes?: Partial<typeof styles>;
    card: GraphQLGiftCardWithDetails;
    onDelete?: () => void;
};

function GiftCard({
    classes,
    card,
    clientId,
    onDelete,
}: CardProps): React.ReactElement {
    const translate = useTranslate();
    const [menuAnchorEl, setMenuAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);
    const { _id: cardId, last3, balance } = card;
    const [showTransactionsModal, setShowTransactionsModal] =
        React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const moneyFormatter = useMoneyFormatter({
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [deleteGiftCard, { loading }] = useGiftCardsMutation<
        DeleteGiftCardResult,
        DeleteGiftCardArguments
    >(deleteGiftCardMutation);

    const label = <>{last3 && `**** ${last3}`}</>;

    const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const openTransactionHistory = (e: React.SyntheticEvent) => {
        e.preventDefault();
        closeMenu();
        setShowTransactionsModal(true);
    };

    const handleDeleteGiftCard = (e: React.SyntheticEvent) => {
        e.preventDefault();
        closeMenu();
        setShowDeleteModal(true);
    };

    const handleSubmitDelete = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        await deleteGiftCard({
            variables: {
                vetspireClientId: clientId,
                cardId,
            },
        });
        closeMenu();
        onDelete?.();
    };

    return (
        <>
            <div className={classnames(classes?.root, styles.root)}>
                <div className={classnames(classes?.info, styles.info)}>
                    <GiftCardIcon />
                    <p>{label}</p>
                    <Chip
                        label={`$${moneyFormatter.format(balance)}`}
                        size="small"
                        classes={{
                            root: styles.chip,
                            sizeSmall: styles.smallChip,
                        }}
                    />
                </div>
                <IconButton
                    classes={{ root: styles.delete }}
                    onClick={handleMenuClick}
                    type="button"
                    aria-controls="actions-menu"
                    size="large"
                >
                    <MoreHorizIcon color="inherit" />
                </IconButton>
            </div>

            <Menu
                id="actions-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                classes={{ list: styles.menu }}
            >
                <MenuItem
                    dense
                    classes={{ root: styles.menuItem }}
                    onClick={openTransactionHistory}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.history',
                    )}
                </MenuItem>
                <MenuItem
                    dense
                    classes={{
                        root: classnames(styles.menuItem, styles.delete),
                    }}
                    onClick={handleDeleteGiftCard}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.delete.label',
                    )}
                </MenuItem>
            </Menu>

            <Dialog open={showDeleteModal} classes={{ paper: styles.dialog }}>
                <DialogTitle className={styles.dialogTitle}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.delete.title',
                        {
                            last3: (
                                <span className={styles.strong}>{last3}</span>
                            ),
                        },
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.dialogText}>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.delete.text',
                            {
                                last3: (
                                    <span className={styles.strong}>
                                        {last3}
                                    </span>
                                ),
                            },
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button
                                className={styles.cancel}
                                type="button"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                {translate(
                                    'vetspireExtension.clientDetails.payments.giftCards.delete.cancel',
                                )}
                            </Button>
                            <Button
                                className={styles.submit}
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmitDelete}
                            >
                                {translate(
                                    'vetspireExtension.clientDetails.payments.giftCards.delete.submit',
                                )}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
            {showTransactionsModal && (
                <TransactionsHistoryDialog
                    clientId={clientId}
                    giftCard={card}
                    onClose={() => setShowTransactionsModal(false)}
                />
            )}
        </>
    );
}

export default GiftCard;
