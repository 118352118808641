import * as React from 'react';
import { addGiftCard as addGiftCardMutation } from 'api/clients/mutations';
import classnames from 'classnames';
import ActionButton from 'components/ActionButton';
import useGiftCardsMutation from 'hooks/useGiftCardsMutation';
import { ReactComponent as GiftCardIcon } from 'assets/giftcard_outlined.svg';
import useTranslate from 'hooks/useTranslate';
import useLocationId from 'hooks/useLocationId';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import { useLazyQuery } from '@apollo/client';
import { checkGiftCardBalance as checkGiftCardBalanceQuery } from 'api/clients/queries';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    AddGiftCardArguments,
    AddGiftCardResult,
    CheckGiftCardResponse,
    GraphQLGiftCardWithDetails,
} from '@bondvet/types/giftCards';
import CheckIcon from '@mui/icons-material/Check';
import styles from './AddGiftCard.module.scss';

export type AddCreditCardProps = {
    clientId: string;
    onAdded?: (newCard: GraphQLGiftCardWithDetails | null) => void;
    addedGiftCard: GraphQLGiftCardWithDetails | null;
};

type FormValues = {
    number: string;
    pin: string;
};

function AddGiftCard({
    clientId,
    onAdded,
    addedGiftCard,
}: AddCreditCardProps): React.ReactElement {
    const translate = useTranslate();
    const vetspireLocationId = useLocationId();
    const [error, setError] = React.useState<string | null>(null);
    const [addGiftCard, { loading: addLoading, error: addError }] =
        useGiftCardsMutation<AddGiftCardResult, AddGiftCardArguments>(
            addGiftCardMutation,
        );

    const moneyFormatter = useMoneyFormatter({
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [
        checkGiftCardBalance,
        { loading: checkLoading, data, error: checkError },
    ] = useLazyQuery<CheckGiftCardResponse, AddGiftCardArguments>(
        checkGiftCardBalanceQuery,
        {
            context: {
                clientName: GRAPHQL_CLIENT_NAMES.giftCards,
            },
        },
    );

    React.useEffect(() => {
        if (addError?.message) {
            setError(addError.message);
        }
    }, [addError?.message]);

    React.useEffect(() => {
        if (checkError?.message) {
            setError(checkError.message);
        }
    }, [checkError?.message]);

    const [formValues, setFormValues] = React.useState<FormValues>({
        number: '',
        pin: '',
    });

    const onChangeFormValues = React.useCallback(
        (newState: Partial<FormValues>) => {
            setFormValues((prev) => ({
                ...prev,
                ...newState,
            }));
            setError(null);
        },
        [],
    );

    const handleSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (clientId && formValues.pin && formValues.number) {
                const { errors, data: newCardData } = await addGiftCard({
                    variables: {
                        vetspireLocationId,
                        vetspireClientId: clientId,
                        giftCardNumber: formValues.number.replace(/\s+/g, ''),
                        pin: formValues.pin,
                    },
                });
                if (!errors) {
                    setFormValues({
                        number: '',
                        pin: '',
                    });
                    onAdded?.(newCardData?.addGiftCard ?? null);
                }
            }
        },
        [
            addGiftCard,
            clientId,
            formValues.number,
            formValues.pin,
            vetspireLocationId,
            onAdded,
        ],
    );

    const checkBalance = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (clientId && formValues.pin && formValues.number) {
                await checkGiftCardBalance({
                    variables: {
                        vetspireLocationId,
                        vetspireClientId: clientId,
                        giftCardNumber: formValues.number.replace(/\s+/g, ''),
                        pin: formValues.pin,
                    },
                });
            }
        },
        [
            checkGiftCardBalance,
            clientId,
            formValues.number,
            formValues.pin,
            vetspireLocationId,
        ],
    );

    const onCardNumberChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value, selectionStart } = e.target;
            const formattedNumber = value
                .replace(/\s+/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim();

            const spacesBeforeCursor = (
                value.slice(0, selectionStart ?? 0).match(/\s/g) || []
            ).length;
            const newSpacesBeforeCursor = (
                formattedNumber.slice(0, selectionStart ?? 0).match(/\s/g) || []
            ).length;
            const cursorPosition =
                (selectionStart ?? 0) +
                newSpacesBeforeCursor -
                spacesBeforeCursor;

            onChangeFormValues({
                number: formattedNumber,
            });
            setTimeout(
                () =>
                    e.target.setSelectionRange(cursorPosition, cursorPosition),
                0,
            );
        },
        [onChangeFormValues],
    );

    const disabled =
        addLoading && checkLoading && formValues.pin && formValues.number;

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <div
                className={classnames(styles.card, {
                    [styles.error]: !!error,
                })}
            >
                <GiftCardIcon />
                <input
                    placeholder="1234 1234 1234 1234"
                    value={formValues.number}
                    maxLength={24}
                    onChange={(e) => onCardNumberChange(e)}
                />
                <input
                    maxLength={6}
                    placeholder="123456"
                    value={formValues.pin}
                    onChange={(e) =>
                        onChangeFormValues({
                            pin: e.target.value,
                        })
                    }
                />
            </div>
            {error && <div className={styles.errorMsg}>{error}</div>}
            {data?.checkGiftCardBalance && (
                <div className={styles.balance}>
                    {translate(
                        data.checkGiftCardBalance.balance
                            ? 'vetspireExtension.clientDetails.payments.giftCards.balanceInfo'
                            : 'vetspireExtension.clientDetails.payments.giftCards.noBalanceInfo',
                        {
                            last3: (
                                <span className={styles.strong}>
                                    {data.checkGiftCardBalance.last3}
                                </span>
                            ),
                            balance: (
                                <span className={styles.strong}>
                                    {`$${moneyFormatter.format(
                                        data.checkGiftCardBalance.balance,
                                    )}`}
                                </span>
                            ),
                        },
                    )}
                </div>
            )}
            {addedGiftCard && (
                <div className={styles.added}>
                    <CheckIcon />
                    <span>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.addedInfo',
                            {
                                last3: addedGiftCard.last3,
                                balance: (
                                    <span className={styles.strong}>
                                        {`$${moneyFormatter.format(
                                            addedGiftCard.balance,
                                        )}`}
                                    </span>
                                ),
                            },
                        )}
                    </span>
                </div>
            )}
            <div className={styles.actions}>
                <ActionButton
                    disabled={!!disabled}
                    type="submit"
                    className={styles.button}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.add',
                    )}
                </ActionButton>
                <ActionButton
                    disabled={!!disabled}
                    type="button"
                    className={styles.button}
                    onClick={checkBalance}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.checkBalance',
                    )}
                </ActionButton>
            </div>
        </form>
    );
}

export default AddGiftCard;
