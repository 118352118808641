import * as React from 'react';
import classnames from 'classnames';
import { Tab, Tabs } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ReactComponent as GiftCardIcon } from 'assets/giftcard.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WithFlyout } from 'lib/types';
import { CreditCard } from 'api/types';
import useTranslate from 'hooks/useTranslate';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import { GraphQLGiftCardWithDetails } from '@bondvet/types/giftCards';
import styles from './CreditCards.module.scss';
import AddCreditCard from './components/AddCreditCard';
import useClientHasPatientsWithActivePlans from './hooks/useClientHasPatientsWithActivePlans';
import useCreditCards from './hooks/useCreditCards';
import Card from './components/Card';
import Reload from './components/Reload';
import DueInvoices from './components/DueInvoices';
import useDueInvoices from './hooks/useDueInvoices';
import AddGiftCard from './components/AddGiftCard';
import useGiftCards from './hooks/useGiftCards';
import GiftCard from './components/GiftCard';

export type CreditCardsProps = {
    classes?: Partial<WithFlyout<typeof styles>>;
    clientId: string;
    chargeNoShowFees: boolean;
};

type TabValue = 'creditCards' | 'giftCards';

function CreditCards({
    classes,
    clientId,
    chargeNoShowFees,
}: CreditCardsProps): React.ReactElement {
    const [tab, setTab] = React.useState<TabValue>('creditCards');
    const handleTabChange = (
        event: React.SyntheticEvent,
        newValue: TabValue,
    ) => {
        setTab(newValue);
    };

    const moneyFormatter = useMoneyFormatter({
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [showInactiveGiftCards, setShowInactiveGiftCards] =
        React.useState(false);

    const {
        creditCards,
        deleteCard,
        refetch: refetchCreditCards,
        isLoading: isLoadingCreditCards,
        setDefault,
    } = useCreditCards(clientId);
    const {
        enabled,
        activeGiftCards,
        inactiveGiftCards,
        refetch: refetchGiftCards,
        loading: isLoadingGiftCards,
        total,
    } = useGiftCards(clientId);
    const {
        orders,
        refetch: refetchDueInvoices,
        isLoading: isLoadingDueInvoices,
    } = useDueInvoices(clientId);
    const translate = useTranslate();
    const [addedGiftCard, setAddedGiftCard] =
        React.useState<GraphQLGiftCardWithDetails | null>(null);

    const { clientHasPatientsWithActivePlans } =
        useClientHasPatientsWithActivePlans(clientId);

    const onReload = React.useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            refetchCreditCards();
            refetchDueInvoices();
        },
        [refetchCreditCards, refetchDueInvoices],
    );

    const isLoading = isLoadingCreditCards || isLoadingDueInvoices;

    const defaultCard = React.useMemo<CreditCard | null>(
        () => creditCards.find((card) => card.isDefault) || null,
        [creditCards],
    );

    const giftCardsCount =
        (activeGiftCards.length ?? 0) + (inactiveGiftCards.length ?? 0);

    return (
        <>
            <hr className={styles.divider} />
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.title',
                    )}
                </span>
                <Reload onClick={onReload} isLoading={isLoading} />
            </header>
            {enabled && (
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    classes={{ root: styles.tabs, indicator: styles.indicator }}
                >
                    <Tab
                        classes={{
                            root: styles.tab,
                            selected: styles.selected,
                        }}
                        icon={<CreditCardIcon />}
                        label={
                            <div className={styles.label}>
                                <span>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.creditCards.tabTitle',
                                    )}
                                </span>
                                <span className={styles.badge}>
                                    {creditCards?.length ?? 0}
                                </span>
                            </div>
                        }
                        value="creditCards"
                    />
                    <Tab
                        classes={{
                            root: styles.tab,
                            selected: styles.selected,
                        }}
                        icon={<GiftCardIcon />}
                        label={
                            <div className={styles.label}>
                                <span>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.giftCards.tabTitle',
                                    )}
                                </span>
                                <span className={styles.badge}>
                                    {giftCardsCount}
                                </span>
                            </div>
                        }
                        value="giftCards"
                    />
                </Tabs>
            )}
            {tab === 'creditCards' && (
                <div className={classnames(classes?.root, styles.root)}>
                    {creditCards.map((card) => (
                        <Card
                            card={card}
                            key={card.id}
                            onDelete={() => deleteCard(card.id)}
                            onSetDefault={() => setDefault(card.id)}
                            chargeNoShowFees={chargeNoShowFees}
                            clientHasPatientsWithActivePlans={
                                clientHasPatientsWithActivePlans
                            }
                        />
                    ))}

                    <AddCreditCard clientId={clientId} />
                </div>
            )}
            {tab === 'giftCards' && isLoadingGiftCards && <CircularProgress />}
            {tab === 'giftCards' && !isLoadingGiftCards && (
                <div className={classnames(classes?.root, styles.root)}>
                    <div>
                        <FormControlLabel
                            className={styles.showInactiveGiftCards}
                            control={
                                <Switch
                                    color="default"
                                    checked={showInactiveGiftCards}
                                    onChange={(value) =>
                                        setShowInactiveGiftCards(
                                            value.target.checked,
                                        )
                                    }
                                />
                            }
                            label={translate(
                                'vetspireExtension.clientDetails.payments.giftCards.showPast',
                            )}
                        />
                    </div>
                    {!!total && (
                        <p>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.total',
                                {
                                    balance: (
                                        <span className={styles.strong}>
                                            ${moneyFormatter.format(total)}
                                        </span>
                                    ),
                                },
                            )}
                        </p>
                    )}
                    {activeGiftCards.map((card) => (
                        <GiftCard
                            clientId={clientId}
                            card={card}
                            key={card._id}
                            onDelete={() => refetchGiftCards()}
                        />
                    ))}
                    {showInactiveGiftCards &&
                        inactiveGiftCards.map((card) => (
                            <GiftCard
                                clientId={clientId}
                                card={card}
                                key={card._id}
                                onDelete={() => refetchGiftCards()}
                            />
                        ))}
                    <AddGiftCard
                        clientId={clientId}
                        onAdded={(newCard) => {
                            refetchGiftCards();
                            setAddedGiftCard(newCard);
                            setTimeout(() => {
                                setAddedGiftCard(null);
                            }, 5000);
                        }}
                        addedGiftCard={addedGiftCard}
                    />
                </div>
            )}
            <hr className={classnames(styles.divider, styles.invoices)} />
            <DueInvoices
                clientId={clientId}
                orders={orders}
                refetchOrders={async () => refetchDueInvoices()}
                defaultCard={defaultCard}
            />
        </>
    );
}

export default CreditCards;
