import * as React from 'react';
import {
    FeatureEnabledQueryResult,
    GraphQLGiftCardWithDetails,
    GraphQLGiftCard,
    GiftCardWithDetailsQueryArguments,
    GiftCardWithDetailsQueryResponse,
    GiftCardsQueryArguments,
    GiftCardsQueryResponse,
    ChargeOrderArguments,
    ChargeOrderResult,
} from '@bondvet/types/giftCards';
import useGiftCardsQuery from 'hooks/useGiftCardsQuery';
import useGiftCardsMutation from 'hooks/useGiftCardsMutation';
import useLocationId from 'hooks/useLocationId';
import {
    giftCards as giftCardsQuery,
    giftCardsFeatureEnabledQuery,
    giftCardWithDetails as giftCardWithDetailsQuery,
} from 'api/clients/queries';
import { chargeOrder as chargeOrderMutation } from 'api/clients/mutations';
import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

type UseGiftCards = {
    enabled: boolean;
    total: number;
    activeGiftCards: ReadonlyArray<GraphQLGiftCardWithDetails>;
    inactiveGiftCards: ReadonlyArray<GraphQLGiftCardWithDetails>;
    refetch: () => void;
    loading: boolean;
    chargeGiftCard: (cardId: string, orderId: string, amount: number) => void;
    chargeLoading: boolean;
};

export default function useGiftCards(vetspireClientId: string): UseGiftCards {
    const vetspireLocationId = useLocationId();
    const { data: featureEnabled, loading: featureEnabledLoading } =
        useGiftCardsQuery<FeatureEnabledQueryResult>(
            giftCardsFeatureEnabledQuery,
        );

    const { data, loading, refetch } = useGiftCardsQuery<
        GiftCardsQueryResponse,
        GiftCardsQueryArguments
    >(giftCardsQuery, {
        variables: {
            vetspireClientId,
        },
    });

    const [detailsLoading, setDetailsLoading] = React.useState(false);
    const [runGetGiftCardDetails] = useLazyQuery<
        GiftCardWithDetailsQueryResponse,
        GiftCardWithDetailsQueryArguments
    >(giftCardWithDetailsQuery, {
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.giftCards,
        },
    });

    const [chargeOrder, { loading: chargeLoading }] = useGiftCardsMutation<
        ChargeOrderResult,
        ChargeOrderArguments
    >(chargeOrderMutation);

    const chargeGiftCard = React.useCallback(
        async (cardId: string, orderId: string, amount: number) => {
            const { data: a } = await chargeOrder({
                variables: {
                    vetspireLocationId,
                    vetspireClientId,
                    cardId,
                    orderId,
                    amount,
                },
            });

            return a;
        },
        [chargeOrder, vetspireClientId, vetspireLocationId],
    );

    const [giftCardsWithDetails, setGiftCardsWithDetails] = React.useState<
        ReadonlyArray<GraphQLGiftCardWithDetails>
    >([]);

    const getGiftCardDetails = React.useCallback(
        (giftCards: ReadonlyArray<GraphQLGiftCard>) => {
            setDetailsLoading(true);
            Promise.all(
                giftCards.map(async (giftCard: GraphQLGiftCard) => {
                    const result = await runGetGiftCardDetails({
                        variables: {
                            vetspireLocationId,
                            vetspireClientId,
                            giftCardId: giftCard._id,
                        },
                    });

                    return result?.data?.giftCardWithDetails;
                }),
            ).then((giftCardData) => {
                setGiftCardsWithDetails(
                    giftCardData.filter(
                        (giftCard) => !!giftCard,
                    ) as ReadonlyArray<GraphQLGiftCardWithDetails>,
                );
                setDetailsLoading(false);
            });
        },
        [runGetGiftCardDetails, vetspireClientId, vetspireLocationId],
    );

    const refetchGiftCardsWithDetails = React.useCallback(async () => {
        const { data: newData } = await refetch();
        await getGiftCardDetails(newData?.giftCards);
    }, [getGiftCardDetails, refetch]);

    React.useEffect(() => {
        if (data?.giftCards && data?.giftCards?.length !== 0) {
            getGiftCardDetails(data?.giftCards);
        }
    }, [data?.giftCards, getGiftCardDetails]);

    return React.useMemo(
        () => ({
            enabled: featureEnabled?.giftCardsFeatureEnabled ?? false,
            total:
                (giftCardsWithDetails.reduce(
                    (acc, { balance }) => (balance ?? 0) + acc,
                    0,
                ) as number) ?? 0,
            activeGiftCards:
                giftCardsWithDetails.filter(({ balance }) => !!balance) ?? [],
            inactiveGiftCards:
                giftCardsWithDetails.filter(({ balance }) => !balance) ?? [],
            refetch: refetchGiftCardsWithDetails,
            loading: featureEnabledLoading || loading || detailsLoading,
            chargeGiftCard,
            chargeLoading,
        }),
        [
            chargeGiftCard,
            chargeLoading,
            detailsLoading,
            featureEnabled?.giftCardsFeatureEnabled,
            featureEnabledLoading,
            giftCardsWithDetails,
            loading,
            refetchGiftCardsWithDetails,
        ],
    );
}
