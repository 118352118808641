import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import Typography from '@mui/material/Typography';
import { format, parse } from 'date-fns';
import useViewerSettings from 'hooks/useViewerSettings';
import { ProviderRightValue } from '@bondvet/types/providers';
import EditExpandedAccessLevers from '../EditExpandedAccessLevers';
import ProviderSelect from '../ProviderSelect';
import {
    DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    type DayWithAdditionalCapacitySlots,
    type BookedAppointments,
    type UpdateExpandedAccessSlots,
} from '../../types';
import styles from './SelectedDay.module.scss';

type SelectedDayProps = {
    date: string;
    capacities: DayWithAdditionalCapacitySlots;
    bookedAppointments: BookedAppointments;
    updateExpandedAccessSlots: UpdateExpandedAccessSlots;
};

export default function SelectedDay({
    date,
    capacities,
    bookedAppointments,
    updateExpandedAccessSlots,
}: SelectedDayProps) {
    const translate = useTranslate();
    const { viewer, rights } = useViewerSettings();
    const isAdmin =
        rights?.vetspireExtension_intradayCapacity ===
        ProviderRightValue.enabled_allRecords;
    const [selectedProviderId, setSelectedProviderId] = React.useState<
        string | null
    >(null);

    const isPast = React.useMemo(() => {
        return format(new Date(), DATE_FORMAT).localeCompare(date) > 0;
    }, [date]);

    React.useEffect(() => {
        if (!isAdmin && viewer?.id) {
            setSelectedProviderId(
                capacities?.providers.find(
                    (provider) => provider.id === viewer.id,
                )?.id ?? null,
            );
        } else if (isAdmin) {
            setSelectedProviderId((prev) => {
                if (
                    prev &&
                    capacities?.providers.some(
                        (provider) => provider.id === prev,
                    )
                ) {
                    return prev;
                }

                return null;
            });
        }
    }, [isAdmin, viewer?.id, capacities?.providers]);

    const selectedProvider = React.useMemo(() => {
        if (selectedProviderId === null) {
            return null;
        }
        return (
            capacities?.providers?.find(
                (provider) => provider.id === selectedProviderId,
            ) ?? null
        );
    }, [selectedProviderId, capacities]);

    return (
        <div className={styles.container}>
            <Typography variant="h3" className={styles.day}>
                {format(
                    parse(date, DATE_FORMAT, new Date()),
                    DISPLAY_DATE_FORMAT,
                )}
            </Typography>
            {isAdmin && (
                <div className={styles.flyoutBlock}>
                    <ProviderSelect
                        value={selectedProviderId}
                        onChange={setSelectedProviderId}
                        providers={capacities?.providers ?? []}
                    />
                </div>
            )}
            {!isAdmin && capacities?.providers.length === 0 && (
                <div
                    className={classnames(styles.flyoutBlock, styles.noShifts)}
                >
                    {translate('vetspireExtension.intradayCapacity.noShifts')}
                </div>
            )}
            {selectedProvider && (
                <EditExpandedAccessLevers
                    date={date}
                    provider={selectedProvider}
                    bookedAppointments={bookedAppointments}
                    readonly={isPast}
                    updateExpandedAccessSlots={updateExpandedAccessSlots}
                />
            )}
        </div>
    );
}
